(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@advise/styleguide"), require("vuex"), require("vue-router"), require("vue"));
	else if(typeof define === 'function' && define.amd)
		define(["@advise/styleguide", "vuex", "vue-router", "vue"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("@advise/styleguide"), require("vuex"), require("vue-router"), require("vue")) : factory(root["@advise/styleguide"], root["vuex"], root["vue-router"], root["vue"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__41df__, __WEBPACK_EXTERNAL_MODULE__5880__, __WEBPACK_EXTERNAL_MODULE__6389__, __WEBPACK_EXTERNAL_MODULE__8bbf__) {
return 
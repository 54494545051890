import Router from "vue-router";

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: "/how-to",
    },
  ],
});

<template>
  <div class="position-relative">
    <v-navigation-drawer
      permanent
      color="#273444"
      width="70"
      floating
      hide-overlay
      disable-resize-watcher
      disable-route-watcher
      touchless
      fixed
    >
      <div class="logoTop d-flex justify-center align-center">
        <router-link to="/">
          <img :src="imgLogo" />
        </router-link>
      </div>
      <div class="sidebarMenuList">
        <v-list dense nav class="px-0 pt-0 pb-0">
          <template v-for="item in items">
            <v-list-item
            v-if="getViewItem(item)"
              :key="item.title"
              link
              :to="item.route"
              :disabled="item.disabled"
              class="itemMenuSidebar"
            >
              <v-list-item-icon
                class="
                  iconMenuSidebard-flex
                  flex-column
                  d-flex
                  align-items-center
                  justify-content-center
                "
              >
                <v-icon size="20" class="imgIcon">{{ item.icon }}</v-icon>
                <!-- <img :class="item.width" class="imgIcon" :src="item.icon" /> -->
                <span class="font10pt mt-2 text-center menutext sideBarText">
                  {{ item.title }}
                </span>
              </v-list-item-icon>
              <!-- <div v-if="item.submenu">
              <v-menu
                open-on-hover
                bottom
                offset-x
                content-class="floatMenu elevation-0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                    class="submenu-dashboard position-absolute"
                  ></div>
                </template>
                <v-list class="sublink">
                  <v-list-item
                    v-for="(item, index) in item.submenu"
                    :key="index"
                    link
                    :to="item.link"
                  >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div> -->
            </v-list-item>
          </template>
        </v-list>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      imgLogo: require("@/assets/icons/logo.png"),
      itemsMenu: [],
      items: [
        {
          title: "How to",
          name: "how-to",
          icon: "fa fa-question-circle",
          width: "w-30",
          /* remplaceIcon:
            "https://image-store.isocialcube.com/advise-images/sidebar_images/ticketStatus.svg", */
          class: "howTo",
          route: "/how-to",
          needAdmin: false,
          allUsers: true,
        },
        {
          title: "Get Help",
          name: "get-help",
          icon: "fa fa-life-ring",
          width: "w-30",
          route: "/get-help",
          needAdmin: false,
          allUsers: true,
        },
        {
          title: "Do It Yourself",
          name: "diy",
          icon: "fa fa-walking",
          width: "w-25",
          route: "/do-it-yourself",
          disabled: false,
          needAdmin: false,
          allUsers: true,
        },
        {
          title: "Ticket Status",
          name: "ticket-status",
          icon: "fa fa-ticket-alt",
          width: "w-35",
          route: "/ticket-status",
          disabled: false,
          needAdmin: false,
          allUsers: true,
        },
        {
          title: "Back Office",
          name: "back-office",
          icon: "fa fa-building",
          width: "w-35",
          route: "/back-office",
          disabled: false,
          needAdmin: true,
          allUsers: false,
        },
        {
          title: "Brief Consulting",
          name: "brief-consulting",
          icon: "fa fa-list-alt",
          width: "w-35",
          route: "/brief-consulting",
          disabled: false,
          needAdmin: true,
          allUsers: false,
        },
      ],
      right: null,
    };
  },
  computed: {
    ...mapGetters("global", {
      context: "getContextBoard",
    }),
    items() {
      return this.over;
    },
  },
  created() {
    this.getDimensions();
    window.addEventListener("resize", this.getDimensions);  
  },
  mounted() {
  },
  methods: {
    getDimensions() {
      const resizeContent = document.getElementById('ResizeModal');
      this.initWidth = document.documentElement.clientWidth;
      (this.initWidth < 1024) ? resizeContent.style.display = 'flex': resizeContent.style.display = 'none'
    },
    ...mapActions("global", ["setContextBoard"]),
    getViewItem(item){
      const is_admin=JSON.parse(sessionStorage.getItem('is_admin'))
      return item.needAdmin == is_admin || item.allUsers
    }
  },
  watch: {
    $route(to, from) {
      /* this.changeUrlFunction(to); */
    },
  },
};
</script>

<style>
.w-35 {
  width: 35%;
}
.v-list-item__icon span {
  font-size: 10px;
}

.v-list--nav .v-list-item,
.v-list--nav .v-list-item:before {
  border-radius: 0;
}
.logoTop {
  height: 60px;
}
.logoTop img {
  width: 35px;
  height: 40px;
  position: relative;
  left: -3px;
  top: 4px;
}
.itemMenuSidebar {
  min-height: 99px !important;
  border-top: 1px solid #464c5e;
  padding: 0 0 !important;
  border-right: 3px solid transparent;
  margin: 0px !important;
}
.itemMenuSidebar .v-list-item__icon {
  min-height: 90px !important;
  margin: 0 !important;
  width: 70px;
  /* padding-top: 20px; */
}
.itemMenuSidebar .v-icon {
  color: #b0b8c4 !important;
}
.itemMenuSidebar:hover,
.itemMenuSidebar.v-list-item--active {
  border-right: 3px solid #00a5ff;
  background-color: #162938;
}
.itemMenuSidebar:hover .v-icon,
.itemMenuSidebar:hover .menutext {
  color: #00a5ff !important;
}
.floatMenu {
  border-radius: 0 !important;
  border-left: 5px solid transparent;
}
.submenu-dashboard {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.sublink {
  background-color: #273444 !important;
  padding: 0;
  border-radius: 0 !important;
}
.sublink
  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #fff !important;
}
.sublink .v-list-item--link {
  padding-right: 50px;
}
.sublink .v-list-item--link:last-child {
  border-top: 1px solid #464c5e;
}
.sublink .v-list-item--link:hover,
.sublink .v-list-item--active {
  color: #fff !important;
  background-color: #00a5ff;
}
.sublink .v-list-item--active:before {
  opacity: 0;
}
.v-list-item--disabled .imgIcon {
  color: #464c5e !important;
}
.v-list-item--disabled span.menutext {
  color: #464c5e !important;
}
.align-items-center {
  align-items: center;
}
.sideBarText {
  font-family: "Oxygen";
  font-style: normal;
  font-weight: 400;
  font-size: 9.6px !important;
  line-height: 11px;
  text-align: center;
  padding: 0rem 0.5rem;
  color: #96a0b8;
}
.itemMenuSidebar.v-list-item--active .sideBarText {
  color: #00a5ff;
}
.itemMenuSidebar.v-list-item--active .imgIcon {
  color: #00a5ff !important;
}
.justify-content-center {
  justify-content: center;
}
</style>

<template>
  <v-app id="sidebar">    
    <app-sidebar />
  </v-app>
</template>

<script>
import AppSidebar from "./components/Sidebar.vue";
export default {
  components: { AppSidebar },
  name: "Navigation",
  async beforeCreate(){
    /* await this.$store.dispatch("global/geoDrivers"); */
  },
};
</script>
<style scoped>
  #sidebar{
    width: 70px !important;
    position: absolute !important;
  }
</style>
